export const themeVars  = {
  navBarIconColor:'#373647',
  navBarTextColor:'#323232',
  dropdownMenuHeight:'44px',
  dropdownMenuTitleFontSize:'14px',
  popoverActionWidth:'100px',
  cellHorizontalPadding:'12px',
  swipeIndicatorActiveBackgroundColor:'#4F86F7',
  productCardBackgroundColor:'#ffffff',
  productCardPadding:'0px',
  productCardDescColor:'#999999',
}