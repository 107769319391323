
//token
export const TOKEN= 'JKSQ_LOGIN_STATUS_TOKEN';
// uid
export const UID ='JKSQ_UID';
//用户信息
export const USER_INFO = 'JKSQ_USER_INFO';
// 是否登录
export const IS_LOGIN = 'JKSQ_IS_LOGIN';
// 城市列表
export const CITY_LIST = 'JKSQ_CITY_LIST'
// openid
export const OPENID = 'JKSQ_OPENID'
