import { createSlice } from "@reduxjs/toolkit";

const loginStateSlice = createSlice({
  name: 'loginState',
  initialState: false,
  reducers: {
    changeLoginState: (state, { payload }) => state = payload
  }
});

export const { changeLoginState } = loginStateSlice.actions;
export default loginStateSlice.reducer

