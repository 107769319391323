import { createSlice } from "@reduxjs/toolkit";

const huoDongStateSlice = createSlice({
  name: 'huoDong',
  initialState: {
    huoDongClass:[], //活动分类
    huoDongAddressList:[], //活动地区
    huoDongSelectAddress: {
      name: '全部地区',
      id: '-1'
    }, //活动选中的地区
    huoDongSelectAddressTab: 0, // //活动选中的地区tab
    huoDongClassInfoList:[], //活动详细分类
    huoDongSelectClass: {
      name: '全部分类',
      id: '-1'
    }, //活动选中的分类
    huoDongSelectClassTab: 0,//活动选中的分类tab
    huoDongPx:{
      value1:0
    } //活动排序
   
  },
  reducers: {
    saveHuoDongClass: (state, { payload }) => void(state.huoDongClass = payload),
    saveHuoDongAddressList: (state, { payload }) => void(state.huoDongAddressList = payload),
    saveHuoDongClassInfoList: (state, { payload }) => void(state.huoDongClassInfoList = payload),
    setHuoDongSelectClass: (state, { payload }) => void(state.huoDongSelectClass = payload),
    setHuoDongSelectAddress: (state, { payload }) => void(state.huoDongSelectAddress = payload),
    setHuoDongSelectClassTab: (state, { payload }) => void (state.huoDongSelectClassTab = payload),
    setHuoDongSelectAddressTab: (state, { payload }) => void (state.huoDongSelectAddressTab = payload),
    setHuoDongPx: (state, { payload }) => void (state.huoDongPx = payload),
  }
});

export const { saveHuoDongClass, saveHuoDongAddressList, saveHuoDongClassInfoList, setHuoDongSelectClass, setHuoDongSelectAddress, setHuoDongSelectClassTab, setHuoDongSelectAddressTab, setHuoDongPx } = huoDongStateSlice.actions;
export default huoDongStateSlice.reducer 