import { createSlice } from "@reduxjs/toolkit";

const goodsClassSlice = createSlice({
    name:'goodsClassList',
    initialState:'',
    reducers:{
        saveGoodsClassList:(state,{payload}) => state = payload
    }
});

export const {saveGoodsClassList} = goodsClassSlice.actions;
export default goodsClassSlice.reducer

