const localStore = {
    setItem: function (key, value) {
        try {
            // localStorage只支持string类型的存储,会自动将value转换成为字符串形式
            // 利用JSON.stringify()方法，将JSON转换成JSON字符串存储
            let jsonValue = JSON.stringify(value)
            localStorage.setItem(key, jsonValue)
        } catch (ex) {
            // // 开发环境下提示 error
            // if (__DEV__) {
            //     console.error('localStorage.setItem报错, ', ex.message)
            // }
        }
    },
    getItem: function (key) {
        let value
        try {
            let jsonValue = localStorage.getItem(key)
            //读取之后要将JSON字符串转换成为JSON对象，使用JSON.parse()方法
            value = JSON.parse(jsonValue)
        } catch (ex) {
            // // 开发环境下提示error
            // if (__DEV__) {
            //     console.error('localStorage.getItem报错, ', ex.message)
            // }
        } finally {
            return value
        }
    },
    clearItem: function (key) {
        //删除值，若传参数，删除单个，若没传，全部删除
        let value
        try {
            if(key){
                value = localStorage.removeItem(key)
            }else {
                value = localStorage.clear()
            }
        } catch (ex) {
            // // 开发环境下提示error
            // if (__DEV__) {
            //     console.error('localStorage.getItem报错, ', ex.message)
            // }
        } finally {
            return value
        }
    },
}
export default localStore