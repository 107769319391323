import { createSlice } from "@reduxjs/toolkit";

const orderAddressSlice = createSlice({
    name:'orderAddress',
    initialState:'',
    reducers:{
        changeOrderAddress:(state,{payload}) => state = payload
    }
});

export const {changeOrderAddress} = orderAddressSlice.actions;
export default orderAddressSlice.reducer

