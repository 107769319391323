import React, { lazy } from 'react'

const Home = lazy(() => import("@/pages/homeTab"));
const Login = lazy(() => import("@/pages/login"));
const LoginByPsd = lazy(() => import("@/pages/login/index_old"));
const Register = lazy(() => import("@/pages/login/register"));
const SearchHistory = lazy(() => import("@/pages/searchHistory"));
const HuoDong = lazy(() => import("@/pages/huodong"));
const HuoDongInfo = lazy(() => import("@/pages/huodong/info"));
const HuoDongList = lazy(() => import("@/pages/huodong/list"));
const FuWu = lazy(() => import("@/pages/fuwu"));
const FuWuInfo = lazy(() => import("@/pages/fuwu/info"));
const FuWuList = lazy(() => import("@/pages/fuwu/list"));
const SecKill = lazy(() => import("@/pages/seckill"));
const SecKillInfo = lazy(() => import("@/pages/seckill/info"));
const GroupBuy = lazy(() => import("@/pages/groupBuy"));
const GroupBuyInfo = lazy(() => import("@/pages/groupBuy/info"));
const GoodsList = lazy(() => import("@/pages/goods"));
const GoodsInfo = lazy(() => import("@/pages/goods/info"));
const GoodsClass = lazy(() => import("@/pages/goodsClass"));
const Pay = lazy(() => import("@/pages/pay"));
const Address = lazy(() => import("@/pages/address"));
const EditAddress = lazy(() => import("@/pages/address/edit"));
const OrderList = lazy(() => import("@/pages/orderList"));
const OrderInfo = lazy(() => import("@/pages/orderInfo"));
const Coupon = lazy(() => import("@/pages/coupon"));
const Collect = lazy(() => import("@/pages/collect"));
const Refund = lazy(() => import("@/pages/refund"));
const RefundList = lazy(() => import("@/pages/refundList"));
const RefundInfo = lazy(() => import("@/pages/refundInfo"));
const EditUserInfo = lazy(() => import("@/pages/my/editUserInfo"));

// 主路由
export const mainRouteConfig = [
    { path: '/', noAuth: true, component: <Login />, noAuth: true },
    { path: "/login", title: "登录", exact: true, component: <Login />, noAuth: true },
    { path: "/loginByPsw", title: "手机号登录", exact: true, component: <LoginByPsd />, noAuth: true },
    { path: "/register/:key", title: "绑定手机号", exact: true, component: <Register />, noAuth: true },

    { path: "/home", title: "首页", exact: true, component: <Home /> },
    { path: "/SearchHistory", title: "搜索", exact: true, component: <SearchHistory /> },

    { path: "/HuoDong", title: "活动", exact: true, component: <HuoDong /> },
    { path: "/HuoDongInfo/:id", title: "活动详情", exact: true, component: <HuoDongInfo /> },
    { path: "/HuoDongList", title: "活动列表", exact: true, component: <HuoDongList /> },
    
    { path: "/FuWu", title: "服务", exact: true, component: <FuWu /> },
    { path: "/FuWuInfo/:id", title: "服务详情", exact: true, component: <FuWuInfo /> },
    { path: "/FuWuList", title: "服务列表", exact: true, component: <FuWuList /> },

    { path: "/SecKill", title: "秒杀", exact: true, component: <SecKill /> },
    { path: "/SecKillInfo/:id", title: "秒杀详情", exact: true, component: <SecKillInfo /> },

    { path: "/GroupBuy", title: "拼团", exact: true, component: <GroupBuy /> },
    { path: "/GroupBuyInfo/:id", title: "拼团详情", exact: true, component: <GroupBuyInfo /> },

    { path: "/GoodsList/:id/:title", title: "商品列表", exact: true, component: <GoodsList /> },
    { path: "/GoodsInfo/:id", title: "商品详情", exact: true, component: <GoodsInfo /> },
    { path: "/GoodsClass/:activeIndex?", title: "商品分类", exact: true, component: <GoodsClass /> },

    { path: "/Pay/:preOrderNo", title: "订单支付", exact: true, component: <Pay /> },

    { path: "/Address/:type?", title: "我的地址", exact: true, component: <Address /> },
    { path: "/EditAddress/:id?", title: "新增/编辑地址", exact: true, component: <EditAddress /> },

    { path: "/OrderList/:linkId", title: "我的订单", exact: true, component: <OrderList /> },
    { path: "/OrderInfo/:orderId", title: "订单详情", exact: true, component: <OrderInfo /> },

    { path: "/Coupon", title: "我的优惠卷", exact: true, component: <Coupon /> },
    { path: "/Collect", title: "我的收藏", exact: true, component: <Collect /> },

    { path: "/Refund/:orderId", title: "申请退款", exact: true, component: <Refund /> },
    { path: "/RefundList", title: "退款列表", exact: true, component: <RefundList /> },
    { path: "/RefundInfo/:orderId", title: "退款列表", exact: true, component: <RefundInfo /> },

    { path: "/editUserInfo", title: "个人资料修改", exact: true, component: <EditUserInfo /> },
];