import { configureStore } from "@reduxjs/toolkit";
import homeTabSlice from './states/homeTabSlice'; //首页tab切换
import goodsClassSlice from './states/goodsClassSlice'; //商品分类列表
import orderAddressSlice from './states/orderAddressSlice'; //订单地址
import loginStateSlice from './states/loginStateSlice'; //登录状态
import huoDongStateSlice from './states/huoDongStateSlice'; //活动功能状态管理
import fuWuStateSlice from './states/fuWuStateSlice'; //服务功能状态管理

const store = configureStore({
	reducer:{
		homeTab:homeTabSlice,
		goodsClassList:goodsClassSlice,
		orderAddress:orderAddressSlice,
		loginState: loginStateSlice,
		huoDongState: huoDongStateSlice,
		fuWuState: fuWuStateSlice
	}
})

export default store