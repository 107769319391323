import { createSlice } from "@reduxjs/toolkit";

const homeTabSlice = createSlice({
    name:'homeTab',
    initialState:'home',
    reducers:{
        changeHomeTab:(state,{payload}) => state = payload
    }
});

export const {changeHomeTab} = homeTabSlice.actions;
export default homeTabSlice.reducer

