import React from 'react';
import {Loading} from 'react-vant';
import './index.scss';

const PageLoading = () => {
    return (
        <div className='loading_wrapper'>
            <Loading type="ball" />
        </div>
    )
}
export default PageLoading
