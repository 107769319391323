import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store'
import { ConfigProvider } from 'react-vant';
import { themeVars } from './assets/js/theme';
import Routers from './routers';
import reportWebVitals from './reportWebVitals';
import { useSelector } from 'react-redux';
import './assets/css/reset.css';
import './assets/css/common.scss';

//登录时路由刷新
const RenderRouter = () => {
  const loginState = useSelector(store => store.loginState)
  console.log(loginState)
  return <Routers login={loginState} />
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ConfigProvider themeVars={themeVars} style={{ width: '100%', height: '100%' }}>
      <RenderRouter />
    </ConfigProvider>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
