import { createSlice } from "@reduxjs/toolkit";

const fuWUStateSlice = createSlice({
  name: 'fuWu',
  initialState: {
    fwClass: [], //服务分类
    fwClassInfo:[],//服务详情分类
    fwSelectClass: {
      text: '全部分类',
      id:''
    }, //服务选中的分类
    fwSelectClassTab: 0,//服务选中的分类tab
    fwAddressList:[],// 服务地区
    fwSelectAddress:{
      text: '全部地区',
      id:''
    },//服务选中的地区
    fwSelectAddressTab:0, //服务选中的地区tab
    fwPx: {
      value1: 0
    } //服务排序
  },
  reducers: {
    saveFwClass: (state, { payload }) => void (state.fwClass = payload),
    saveFwClassInfo: (state, { payload }) => void (state.fwClassInfo = payload),
    saveFwSelectClass: (state, { payload }) => void (state.fwSelectClass = payload),
    saveFwSelectClassTab: (state, { payload }) => void (state.fwSelectClassTab = payload),
    saveFwAddressList: (state, { payload }) => void (state.fwAddressList = payload),
    saveFwSelectAddress: (state, { payload }) => void (state.fwSelectAddress = payload),
    saveFwSelectAddressTab: (state, { payload }) => void (state.fwSelectAddressTab = payload),
    saveFwPx: (state, { payload }) => void (state.fwPx = payload),
  }
});

export const { saveFwClass, saveFwClassInfo, saveFwSelectClass, saveFwSelectClassTab, saveFwAddressList, saveFwSelectAddress, saveFwSelectAddressTab, saveFwPx } = fuWUStateSlice.actions;
export default fuWUStateSlice.reducer 