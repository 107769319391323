import * as React from "react";
import { Routes, Route, HashRouter as Router, Navigate } from "react-router-dom";
import localStore from '@/util/localStorage';
import { TOKEN } from '@/constants/sessionName';
import { Empty } from 'react-vant';
import PageLoading from '../components/pageLoading';
import { mainRouteConfig } from "./config";
import AutoTop from './autoTop';

const renderRouter = (routerList) => {
    return routerList.map(item=>{
        const { path, exact, noAuth } = item; 
        const token = localStore.getItem(TOKEN);
        return <Route
            key={path}
            exact={exact}
            path={path}
            element={!noAuth && !token ? <Navigate to="/login" replace={true} /> : item.component}
        >
        </Route >
    })
} 

const RoutersDom = (props) => {
    console.log(props.login, props)
    return (
        <Router>
            <React.Suspense fallback={<PageLoading />}>
                <AutoTop>
                    <Routes>
                        {
                            renderRouter(mainRouteConfig)
                        }
                        < Route path="*" element={<Empty description="404" />} />
                    </Routes>
                </AutoTop>
            </React.Suspense>
        </Router>
    )
}

// const RoutersDom = () => {
//     return (
//         <Router>
//             <React.Suspense fallback={<PageLoading />}>
//                 <AutoTop>
//                     <Routes>
//                         {
//                             mainRouteConfig.map((item) => {
//                                 const { path, exact, noAuth } = item;
//                                 console.log(mainRouteConfig)
//                                 return <Route
//                                     key={path}
//                                     exact={exact}
//                                     path={path}
//                                     element={!noAuth && !localStore.getItem(TOKEN) ? <Navigate to="/login" replace={true}/> : item.component }
//                                 >
//                                 </Route >;
//                             })
//                         }
//                         < Route path="*" element={<Empty description="404" />} />
//                     </Routes>
//                 </AutoTop>
//             </React.Suspense>
//         </Router>
//     )
// }


export default React.memo(RoutersDom)